import { showReviews } from "./views/reviews/reviews";
import { createReviewForm } from "./views/reviews/create-review-form";

const supportedAPI = ["init", "fetch_reviews", "create_review_form"]; // enlist all methods supported by API (e.g. `mw('event', 'user-login');`)

/**
    The main entry of the application
    */
function app(window) {
  console.log("JS-Widget starting");

  // set default configurations
  let configurations = {
    someDefaultConfiguration: false,
  };

  // all methods that were called till now and stored in queue
  // needs to be called now
  let globalObject = window[window["tappi-review-widget"]];
  let queue = globalObject.q;
  if (queue) {
    for (var i = 0; i < queue.length; i++) {
      if (queue[i][0].toLowerCase() == "init") {
        configurations = extendObject(configurations, queue[i][1]);
        console.log("JS-Widget started", configurations);

        if (configurations.joinCode) {
          const defaultCardStyles = {
            background:
              "linear-gradient(142.13deg, rgba(255, 255, 255, 0.196) 1.8%, rgba(255, 255, 255, 0.217) 99.75%)",
            border: "1px solid #EAECF0",
            borderRadius: "20px",
            height: "220px",
            textColor: "#475467",
            backdropFilter: "blur(75.695px)",
          };

          apiHandler("fetch_reviews", {
            joinCode: configurations.joinCode,
            targetId: configurations.targetId,
            cardStyles: {
              ...defaultCardStyles,
              ...configurations.reviewCardStyles,
            },
            logo: configurations.logo || "primary",
            title: configurations.title || "Testimonials",
            description:
              configurations.description ||
              function (rating) {
                return `<p>Our customers rate us ${rating}<span style="color: #FFB400;">★</span>. Here's what they have to say</p>`;
              },
            limit: configurations.limit || 20,
            addReviewUrl: configurations.addReviewUrl || null,
          });
        }
      } else apiHandler(queue[i][0], queue[i][1]);
    }
  }

  // override temporary (until the app loaded) handler
  // for widget's API calls
  globalObject = apiHandler;
  globalObject.configurations = configurations;
}

/**
    Method that handles all API calls
    */
function apiHandler(api, params) {
  if (!api) throw Error("API method required");
  api = api.toLowerCase();

  if (supportedAPI.indexOf(api) === -1)
    throw Error(`Method ${api} is not supported`);

  console.log(`Handling API call ${api}`, params);

  switch (api) {
    case "fetch_reviews":
      showReviews(params);
      break;
    case "create_review_form":
      createReviewForm(params);
      break;
    default:
      console.warn(`No handler defined for ${api}`);
  }
}

function extendObject(a, b) {
  for (var key in b)
    if (Object.prototype.hasOwnProperty.call(b, key)) a[key] = b[key];
  return a;
}

app(window);
