export async function getTappiReviews(joinCode, offset = 0, limit = 12) {
  const response = await fetch(
    `https://neo-ke.tappi.app/reviews?join_code=${joinCode}&offset=${offset}&limit=${limit}&show_number=false`,
    {
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzM4NCJ9.eyJpc3MiOiJ0YXBwaS1yZXZpZXdzIiwic3ViIjoiM2ZkMDA0NTQ1ODBkZTQ0ZWEyMTZkOGI3YjIzNDI2N2EyYTZhNmFlYzdlNTZkMmIzOGU2NDFhNDU1OTdhZjBmMiIsImlhdCI6MTcwNjk1NTc5NywiZXhwIjoyMDIyMzE1Nzk3fQ.tiIhBssZtEmGqhmlJrEhQvKxYLld_7pm-Ke7MnMiVZFJPw3Hrd2t_aMM0jLhPGV8",
      },
    },
  );

  if (!response.ok) {
    return {
      data: {
        business_reviews_list: [],
      },
    };
  }
  return response.json();
}

export async function getTappiReviewsForDomain(
  domainId,
  offset = 0,
  limit = 24,
) {
  const response = await fetch(
    `https://neo-ke.tappi.app/reviews/domain?domain_id=${domainId}&limit=${limit}&offset=${offset}&show_number=false`,
    {
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzM4NCJ9.eyJpc3MiOiJ0YXBwaS1yZXZpZXdzIiwic3ViIjoiM2ZkMDA0NTQ1ODBkZTQ0ZWEyMTZkOGI3YjIzNDI2N2EyYTZhNmFlYzdlNTZkMmIzOGU2NDFhNDU1OTdhZjBmMiIsImlhdCI6MTcwNjk1NTc5NywiZXhwIjoyMDIyMzE1Nzk3fQ.tiIhBssZtEmGqhmlJrEhQvKxYLld_7pm-Ke7MnMiVZFJPw3Hrd2t_aMM0jLhPGV8",
      },
    },
  );

  if (!response.ok) {
    return {
      data: {
        business_reviews_list: [],
      },
    };
  }

  return response.json();
}

export async function getCombinedTappiReviews(
  joinCode,
  offset = 0,
  limit = 12,
) {
  const response = await fetch(
    `https://neo-ke.tappi.app/reviews/combined?join_code=${joinCode}&offset=${offset}&limit=${limit}&show_number=false&include_domain_reviews=true`,
    {
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzM4NCJ9.eyJpc3MiOiJ0YXBwaS1yZXZpZXdzIiwic3ViIjoiM2ZkMDA0NTQ1ODBkZTQ0ZWEyMTZkOGI3YjIzNDI2N2EyYTZhNmFlYzdlNTZkMmIzOGU2NDFhNDU1OTdhZjBmMiIsImlhdCI6MTcwNjk1NTc5NywiZXhwIjoyMDIyMzE1Nzk3fQ.tiIhBssZtEmGqhmlJrEhQvKxYLld_7pm-Ke7MnMiVZFJPw3Hrd2t_aMM0jLhPGV8",
      },
    },
  );

  if (!response.ok) {
    return {
      data: {
        business_reviews_list: [],
      },
    };
  }

  const data = await response.json();

  if (!data.data) {
    return {
      data: {
        business_reviews_list: [],
      },
    };
  }

  return data;
}
